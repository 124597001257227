<template>
  <div class="mercedes">
    <div class="hero">
      <h1>Mercedes</h1>
    </div>

    <div class="strip">
      <p>April 2021 <span>✹ </span></p>
      <p class="index">003</p>
    </div>

    <div class="info">
      <div class="context">
        <h2>The context</h2>
        <p>
          After a successful workshop with the
          <a
            href="https://www.groupechopard.com/"
            target="_blank"
            rel="noopener noreferrer"
            >Chopard group</a
          > in december 2020, they came back 4 months later with another mission. The goal was to design and develop in 4 days a website where the user has the possibility to design his own covering for the Mercedes Actros (or choose existing one) to promote Chopard's new offer to realize covering.
        </p>
      </div>
      <div class="right-info">
        <div class="contribution">
          <h2>My contribution</h2>
          <p>
            I was alone on this mission so I had to design the website UI and then to code it. For this mission, I choose to implement a 3D visualisation model of the truck for better experience, so I had the opportunity to increase my webGL skills in addition to the UI design part of the work wich I'm not really accustomed to that since in workshops I'm mostly implicate in the development work.
          </p>
        </div>

        <div class="result">
          <h2>The result</h2>
          <p>
            The client is satisfied with the solution I came up with.
          </p>
        </div>
      </div>
    </div>

    <div class="mockup-holder">
      <div class="mockup">
        <img
          src="../../assets/images/projects/mercedes.jpg"
          alt="the mercedes actros covering website design"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mercedes {
  margin: 5rem 2rem;
  @media screen and (max-width: 800px) {
    margin: 0 1rem;
  }
}

h1 {
  font-size: 15vw;
  line-height: 100%;
  margin: 0;
  @media screen and (min-width: 1700px) {
    font-size: 25rem;
  }
  @media screen and (max-width: 800px) {
    font-size: 7rem;
  }
}

a {
  color: $blue;
  &:hover {
    text-decoration: underline;
  }
}

h2 {
  margin: 0;
  font-family: $font-body;
  color: $blue;
  font-size: 4rem;
  font-variation-settings: "wght" 400;
  font-weight: 400;
  @media screen and (max-width: 800px) {
    font-size: 3rem;
  }
}

.hero {
  height: 40vh;
  display: flex;
  align-items: flex-end;
}

.info {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  p {
    padding: 1rem 0;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
}
.context,
.right-info {
  width: 40%;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
}

.strip {
  display: flex;
  justify-content: space-between;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  p {
    padding: 0.5rem 0;
    font-size: 5rem;
    font-variation-settings: "wght" 300;
    @media screen and (max-width: 800px) {
      font-size: 2rem;
    }
  }
  .index {
    text-align: end;
  }
  span {
    color: $green;
  }
}

.mockup-holder {
  margin: 5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.mockup {
  width: 50%;
  max-width: 100rem;
  img {
    width: 100%;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
  }
}
</style>

<script>
export default {
  name: "Mercedes",
};
</script>
